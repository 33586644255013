@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  text-align: center;
  display: none;
  overflow: hidden;
  width: 100%;
  line-height: normal;
  color: $color-dark-pink;
  @include breakpoint($large-up) {
    text-align: left;
    line-height: 18px;
  }
  .slick-active & {
    display: block;
  }
  &__offer-text {
    display: inline;
    p {
      display: inline;
      margin: 0;
      font-size: 10px;
      @include breakpoint($large-up) {
        font-size: 12px;
      }
    }
  }
  a {
    font-size: 10px;
    @include breakpoint($large-up) {
      font-size: 12px;
    }
  }
}
