///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
///

// Layout
$max-width: 1024px;
$mobile: 320px;
$tablet: 768px;
$desktop: 1024px;
$desktop-wide: 1280px;
$desktop-x-wide: 1366px;

$global-header-height: 165px;

$basic-grid-gutter: 30px;
$basic-grid-margin: 15px;
$basic-grid-margin-mobile: 10px;

// Max widths for formatters and content containers
$container-max-width-landscape: 1440px !default;

// Content containers outer pad vars
$container-pad-small: 20px !default;
$container-pad-landscape: 45px !default;

// Media queries
// global/_media-queries.scss
$small-range: (0, 640px); // iPhone: $small-up, $small-only
$small-potrait-range: (max-width 767px) (orientation portrait); // Mobile potrait
$small-landscape-range: (max-width 767px) (orientation landscape); // Mobile landscape
$medium-range: (641px, 768px); // iPad portrait: $medium-up, $medium-only
$medium-potrait-range: (width 768px 1024px) (orientation portrait); // iPad portrait
$medium-landscape-range: (width 768px 1024px) (orientation landscape); // iPad landscape
$large-range: (769px, 1024px); // iPad landscape: $large-up, $large-only
$xlarge-range: (1025px, 1279px); // Desktop interim: $xlarge-up, $xlarge-only
$xxlarge-range: (1280px); // Everything above: $xxlarge-up
$wide-short-range: (2, 1); // Short-wide aspect: $wide-short
$front-tall-range: (760px); // minimum height for front page tall nav
$nav-tall-range: (640px); // minimum height for nav touts
$product-normal-range: (641px, 1279px); // Desktop interim: $product-normal-up, $product-normal-only
$product-wide: (1280px); // Desktop interim: $product-wide-up
$small-range1: (max-width 767px); // Mobile range.
$medium-range1: (width 768px 1024px); // ipad range.
$iphonex-landscape-only: (width 375px 812px) (orientation landscape) (-webkit-device-pixel-ratio 3);
$ipad-pro-portrait-only: (width 834px 1194px) (orientation portrait); // iPad Pro portrait

// Typography
$main-font: 'Open-Sans-Regular', Arial !default;
$main-font-regular: $main-font;
$main-font-light: 'Brandon Text Light' !default;
$main-font-medium: 'Brandon Text Medium' !default;
$main-font-bold: 'Brandon Text Bold' !default;
$main-font-book: 'Brandon Text Bold' !default;
$base-font-family: $main-font;
$header-font-family: $main-font-bold;
$header-font-bold: 'Open-Sans-Bold', Arial !default;

// Sizes
$base-font-size: 14px;
$base-line-height: 1.45em;

// Colors (http://sachagreif.com/sass-color-variables/)
$color-black: #000000;
$color-cerise: #e0349b;
$color-green: #090 !default;
$color-gray: #d0d0d0;
$color-gray-background: #eff0f0;
$color-gray-border: #bbbbb9;
$color-gray-tooltip: #b7b7b8;
$color-gray-light: #d4d4d4;
$color-gray-medium: #f9f9f9;
$color-gray-dark: #888888;
$color-gray-alt: #d1d2d1;
$color-gray-light-alt: #eff0f0;
$color-gray-dark-alt: #636363;
$color-gray-border-section: #999999;
$color-gray-light-text: #999999;
$color-gray-dark-text: #333333;
$color-gray-light-border: #dbdbdb;
$color-darkgray: #a9a9a9;
$color-lightgray: #666666;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$tick: '\2713';
$cross: '\2715';
$color--success: #458745 !default;
$color-silver: #c2c2c2;

$color-red: #ff0000;
$color-white: #ffffff;
$color-pink: #ff4661;
$color-pink-red: #ef4963;
$color-dark-pink: #e60995;
// $color-light-brown: #f7f4ef;
$color-light-brown: #f0f0f0; // This is gray now, they used brown before for everything
$color-beige-dark: #afadaa;
$color-beige-background: #f8f4f1;
$color-nude: #f3efea;
$color-beige: $color-nude; //$color-beige: #F1EEE9; very close values
$color-light-gray: #cccccc;
$color-border-gray: #959595;
$color-facebook: #3b5998;
$color-twitter: #42a8df;
$color-linkedin: #007bb6;
$color-instagram: #517fa4;
$color-pinterest: #cb2027;
$color-google-plus: #d34836;
$color-tumblr: #35465c;
$color-youtube: #cd201f;
$color-strong-red: #d51635;
$color-light-orange: #fef2f2 !default;
$color-lighter-green: #07bb5b !default;
$color-medium-blue: #4fa1ad !default;

$color-success-msg: #087443 !default;
$color-error-msg: #a80000 !default;
$color-alert-msg: #b54708 !default;

$colors: (
  color-black: $color-black,
  color-white: $color-white,
  color-light-brown: $color-light-brown,
  color-light-gray: $color-light-gray,
  color-pink: $color-pink,
  color-google-plus: $color-google-plus,
  color-pinterest: $color-pinterest,
  color-youtube: $color-youtube,
  color-twitter: $color-twitter,
  color-linkedin: $color-linkedin,
  color-facebook: $color-facebook,
  color-instagram: $color-instagram,
  color-tumblr: $color-tumblr
);

// System colors
$color-focus: #4d90fe;

// Skeleton Shimmer Colors
$color-shimmer-grey: #edeef1;
$color-shimmer-grey-light: #f6f7f8;

// Background Color
$base-background-color: $color-white;

// Font Colors
$base-font-color: $color-black;
$base-accent-color: $color-pink;

// Link Colors
$base-link-color: $color-black;
$hover-link-color: $color-black;
$base-button-color: $color-black;
$hover-button-color: $hover-link-color;

// Border color
$base-border-color: $color-light-gray;

// 2019 Redesign - new colors
$cr19-dark-pink: #e51174;
$cr19-light-gray: #f9f9f9;
$cr19-dark-gray: #737373;
$cr19-text-pink: $cr19-dark-pink;
$cr19-text-white: $color-white;
$cr19-text-black: $color-black;
$cr19-text-light-gray: $cr19-light-gray;
$cr19-text-dark-gray: $cr19-dark-gray;
$cr19-error: #e60000;
$cr19-bg-pink: $cr19-dark-pink;
$cr19-bg-white: $color-white;
$cr19-bg-black: $color-black;
$cr19-bg-light: $cr19-light-gray;
$cr19-bg-med: $cr19-dark-gray;
$cr19-bg-dark: $cr19-dark-gray;
$cr19-shadow-black: rgba($color-black, 0.35);
$cr19-shadow-black-100: rgba($color-black, 1);

// END 2019 Redesign - new colors

// img background color
$color-gray-light-img: #f5f5f5;

// Adyen Border color:
$color-light-blue: #B9C4C9;

// Sprites
$sprites-sprite-dimensions: true;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// LBO default SCSS variable configuration for not loading of LBO related CSS to all locales
$lbo-enabled: false !default;

// Check mark and cross used in tooltip, password validation.
$check-mark: '\2713';
$cross-mark: '\2715';

$cart-spacing: 10px; // common shared margin/paddings

$max-width-pages: 1320px;

$utils-height: 28px;
$utils-dropdown-height: 15px;
$utils-offset: -50px;
$utils-height--sticky: 92px;
$utils-offset--sticky: 20px;
$mobile-nav-width: 260px;
$mobile-nav-height: 92px;
$mobile-checkout-nav-height: 92px;

// Adaptive placeholder vars
$ap-default: (
  height: 3em,
  margin: 1em,
  border: 1px,
  border-radius: 0px,
  font-size: 1em,
  border-color: $color-black,
  label-color: $color-black,
  active-color: $color-black,
  valid-color: $color-black,
  invalid-color: $color-red,
  placeholder-background-color: $color-white,
  textarea: false
);

///
/// Checkout pages styling
/// This styles the perlgem cart pages found at:
/// ~/perlgem/sites/bobbibrown/na_ms/tmpl/*
///

// headers in checkout-viewcart
// cart item in checkout-cart-item
$cart-item-product-width: 16%;
// $cart-item-image-width: 66%; //max-width 80px
$cart-item-image-padding: 17%;
$cart-item-container-width: 100% - $cart-item-product-width;
$cart-item-desc-width: 43%;
$cart-item-qty-width: 14%;
$cart-item-price-width: 19%;
$cart-item-total-width: 14%;
$cart-item-remove-width: 10%;
$checkout-price-column: 36px;

//getting rid of these, to arbitraty:
$cart-spacing: 10px; // common shared margin/paddings
$checkout-spacing: 20px; // also common
$cart-line: 1px solid $color-gray-border-section; // line used everywhere
$product-width-mobile: 25%;
$product-width-desktop: 25%;
// description
$desc-width-mobile: 60%;
$desc-width-desktop: 35%;

// price
$price-width: 15%;
// replenishment
$replenishment-width-desktop: 20%;
// qty
$qty-width: 15%;

// total
$total-width-desktop: 10%;

// keeping
$checkout-pushdown: 43px; // used to nudge content down from top
$viewcart-pushdown: 35px; // used to nudge content down from top
$cart-container-width: $max-width;
//$checkout-sidebar-width: percentage(379px/$cart-container-width);
$checkout-sidebar-width: percentage(273px / $cart-container-width);
//$cart-sidebar-width: percentage(218px/$cart-container-width); // this will override
$cart-sidebar-width: $checkout-sidebar-width; //same as onepage

$mobile-outer-gutter: 25px;
$header-collapsed-height-pc: 82px;
$header-collapsed-height: 118px;

$min-nav: '.is-min-nav';

$cr19: false !default; // 2019 Redesign
$nav-height: 90px !default;
$mobile-top-offer-height: 40px !default;
$top-offer-height: 40px !default;

@if $cr19 == true {
  $mobile-nav-height: 56px;
}

// vars to use for reviews styling
$reviews_suffix: ' out of 5' !default;
$helpful_review_label: 'Helpful (' !default;
$not_helpful_review_label: 'Not Helpful (' !default;

$has-ppu: false !default;

// opc__updates
$opc_update: false !default;