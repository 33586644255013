@import 'icons';
// @todo deprecate this when svg icons are rolled out to all locales

// Common styles used by all icons in HTML and CSS
@mixin font-icon-base() {
  font-family: 'icons';
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// Replace text with a font icon
@mixin font-icon-replace($pseudo) {
  position: relative;
  right: 9999px;
  &:#{$pseudo} {
    position: absolute;
    height: 100%;
    text-align: center;
    top: 0;
    right: -9999px;
  }
}

// Generate classes for each icon
@each $font-icon in $font-icons {
  .icon-#{nth($font-icon, 1)}:before {
    content: nth($font-icon, 2);
  }
}

// true as second options returns only path
$icon-path: image-url('', true) + 'icons/output/fonts';
// Font Source Files
@include font-face(
  'icons',
  font-files('icons/icons.eot#iefix', 'icons/icons.woff', 'icons/icons.ttf', 'icons/icons.svg#icons'),
  'icons/icons.eot',
  normal,
  normal
);
